// Telerik
$border-radius: 2px;
$dark-theme: false;
$primary: #0e8599;
$secondary: #159fc3;
$info: #0dcaf0;
$success: #198754;
$warning: #ffc107;
$error: #dc3545;
$body-color: #212529;
$body-bg: #ffffff;
$headings-text: #212529;
$subtle-text: #4b6876;
$disabled-text: #8f8f8f;
$component-text: #212529;
$component-bg: #ffffff;
$base-text: #212529;
$base-bg: #f8f9fa;
$hovered-text: #16181b;
$hovered-bg: #e9ecef;
$selected-text: #ffffff;
$selected-bg: #0e8599;
$kendo-button-text: #212529;
$kendo-button-bg: #e4e7eb;
$link-text: #0e8599;
$link-hover-text: #0dcaf0;
$series-a: #0e8599;
$series-b: #6f42c1;
$series-c: #20c997;
$series-d: #198754;
$series-e: #ffc107;
$series-f: #dc3545;

// Tricloud
$selected: #C3E0E5;
$selected-hover: #B5D2D7;
$light-contrast: #f8f9fa;

$app-header: $primary;
$grid-header: $primary;
$tile-header: $light-contrast;
$dialog-header: $primary;

// Override Telerik
$input-btn-focus-width: 0.1rem;
$font-family-sans-serif: 'IBM Plex Sans', Arial, sans-serif;

// Bugfix in telerik
$kendo-input-spinner-icon-offset: 2px;