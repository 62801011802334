@import 'theme';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');
@import '../wwwroot/lib/progress/kendo-theme-bootstrap/dist/all.scss';
@import '../wwwroot/lib/bootstrap/scss/bootstrap.scss';

#body {
    .primary {
        color: $primary;
    }

    .invalid, .invalid * {
        color: $error !important;
    }
    /* Styling of Telerik TreeView */
    #asset-tree, #device-tree {
        .k-treeview {
            position: relative;

            span.k-treeview-leaf {
                transition: none;
                padding: 0;

                > div {
                    padding: 6px 12px;
                    display: flex;
                    align-items: center;
                }

                .openContextMenuEllipsis {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 36px;
                    background-color: transparent;
                    transition: none;

                    &:hover {
                        background-color: inherit;
                        filter: brightness(95%);
                    }

                    > span {
                        pointer-events: none;
                    }
                }

                &:not(:hover):not(.k-selected) {
                    .openContextMenuEllipsis {
                        display: none;
                    }
                }
            }

            .k-treeview-lines {
                /* Disable inline block padding */
                padding-inline-start: 0px;

                span {
                    color: $base-text;
                    border: none;
                    border-radius: $border-radius;
                    box-shadow: none;
                }

                .k-treeview-leaf {
                    width: 100%;

                    > div {
                        width: 100%;
                    }

                    .editNodeArea {
                        background-color: $selected;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        padding: 3px;

                        input,
                        .validationErrorBox > span {
                            width: 100% !important; //Important to override element styles/properties
                            max-width: 100%;
                        }

                        .validationErrorBox {
                            > span {
                                color: red;
                                display: block;
                                word-wrap: break-word;
                                width: 200px;
                                white-space: normal;
                            }
                        }
                    }

                    .k-selected > .k-textbox {
                        /* Ignore padding of k-treeview selected node, when showing a textbox (When node is being edited)*/
                        margin: -6px -6px;
                    }

                    &.k-selected {
                        /* BGColor of selected tree node */
                        background-color: $selected;
                    }

                    > .k-textbox,
                    &.k-state-hover > .k-textbox {
                        /* Ignore padding of k-treeview, of non selected nodes, when showing a textbox (When node is not being edited)*/
                        margin: -6px -6px;
                    }

                    &:hover,
                    &.k-state-hover {
                        /*Default hover color for other components */
                        background-color: $selected-hover;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .icon-container {
        height: 335px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: space-between;
        overflow-y: auto;
        list-style-type: none;
        padding: 0px;
        padding-bottom: 10px;
        margin: 0px;
        text-align: center;
        align-items: center;
        background-color: $body-bg;
        border: 1px solid #dee2e6;
    }

    .icon-item {
        vertical-align: top;
        display: inline-block;
        text-align: center;
        padding-top: 5px;
        height: 70px;
        width: 120px;
        margin: 5px;
        border: 1px solid lightgray;
        background-color: white;
    }


    .icon-item-selected {
        border: 1px solid $selected;
        background-color: $selected;
    }

    .icon-item:hover {
        cursor: pointer;
        border: 1px solid $selected-hover;
        background-color: $selected-hover;
    }

    .icon-caption {
        /* Make the caption a block so it occupies its own line. */
        display: block;
        font-size: 0.7rem;
        padding-top: 0.5rem;
    }

    .noTextSelect {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -moz-user-select: none;
        /* Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    }

    *:focus-visible {
        outline: none;
    }

    .btn-primary {
        color: #fff;
        background-color: #1b6ec2;
        border-color: #1861ac;
    }

    #app {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: -1px;
    }

    .top-row > a,
    .main .top-row .btn-link {
        color: #fff;
        white-space: nowrap;
        margin-left: 1.5rem;
    }

    .main {
        /*flex: 1;*/
        display: flex;
        justify-content: flex-start;
        flex-grow: 1;
        flex-direction: column;
        /*overflow: hidden;*/
        position: relative;
    }

    .main .top-row a:first-child {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .splashscreen {
        position: absolute;
        top: 30vh;
        width: 100%;
        text-align: center;
    }
    /* Styling of Toolbar Panels with Content Area streching to bottom inside TelerikLayoutPanels*/
    .panelToolbarArea {
        height: 72px;
        width: 100%;
        float: left;
    }

    .panelContentFillArea {
        height: calc(100% - 72px) !important;
    }
    /* Telerik Grid */
    /* Set Column Header Color and Text color*/
    .k-grid-header .k-header {
        background-color: $primary;
        color: white;
    }

    .k-grid-header-sticky {
        background-color: $primary;
        color: white;
    }
    /* Set color of grouping header */
    .k-grouping-header {
        background-color: $primary !important;
        color: white !important;
    }
    /* Set sort arrow color desc */
    .k-i-sort-desc-sm::before {
        color: white;
    }
    /* Set sort arrow color acs */
    .k-i-sort-asc-sm::before {
        color: white;
    }
    /* Set hover cursor to Hand */
    .k-grid td {
        cursor: pointer;
    }

    .k-pager-numbers .k-link.k-selected {
        background-color: $primary !important;
        border-color: $primary !important;
    }

    .k-pager-nav.k-link {
        color: $primary !important;
    }
    /* Set BG color of alternate rows to white. */
    .k-grid {
        .k-alt, .k-detail-row {
            background-color: white !important;

            &:hover:not(.k-detail-row) {
                background-color: rgba(0, 0, 0, 0.075) !important;
            }
        }
    }
    /* Styling of TileLayout (Dashboard) */
    .k-tilelayout {
        background-color: $body-bg !important;
        padding: 0rem !important;
    }

    .k-tilelayout .k-tilelayout-item-header.k-card-header {
        background-color: $tile-header;
    }
    /* If any Content Area of a Tile is filling out more than can be shown in the height, show a scrollbar.*/
    .k-tilelayout-item-body.k-card-body.k-overflow-hidden {
        overflow-y: auto !important;
    }
    /* Styling of Telerik DropDownList */
    .k-list .k-item.k-selected,
    .k-list-optionlabel.k-selected {
        background-color: $primary !important;
        /* Set BG color of selected Item in Dropdown */
    }
    /* Styling Telerik Tabs (Tabstrip)*/
    .k-tabstrip-items .k-item.k-active,
    .k-tabstrip-items .k-item.k-selected {
        // background-color: $primary !important;
        // color: $selected-text !important;
        box-shadow: inset 0 -1px 0 0 $primary !important;
        text-shadow: 0 0 0.03rem $primary;
        border-radius: 0;
    }

    .k-tabstrip-content:focus,
    .k-tabstrip-content.k-focused,
    .k-tabstrip > .k-content:focus,
    .k-tabstrip > .k-content.k-focused {
        outline-width: 0px !important;
        outline-style: none !important;
    }

    .k-tabstrip-items .k-item {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
        color: initial;
        color: $primary;

        &:hover {
            color: $primary;
            box-shadow: inset 0 -1px 0 0 $primary !important;
        }
    }

    .connectedCell {
        color: $success;
    }

    .disconnectedCell {
        color: $error;
    }

    .waitingCell {
        color: $warning;
    }

    .navbar-toggler {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .valid.modified:not([type=checkbox]) {
        outline: 1px solid #26b050;
    }

    .validation-message {
        color: red;
    }

    #blazor-error-ui {
        background: lightyellow;
        bottom: 0;
        box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
        display: none;
        left: 0;
        padding: 0.6rem 1.25rem 0.7rem 1.25rem;
        position: fixed;
        width: 100%;
        z-index: 1000;
    }

    #blazor-error-ui .dismiss {
        cursor: pointer;
        position: absolute;
        right: 0.75rem;
        top: 0.5rem;
    }

    .blazor-error-boundary {
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNDkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIG92ZXJmbG93PSJoaWRkZW4iPjxkZWZzPjxjbGlwUGF0aCBpZD0iY2xpcDAiPjxyZWN0IHg9IjIzNSIgeT0iNTEiIHdpZHRoPSI1NiIgaGVpZ2h0PSI0OSIvPjwvY2xpcFBhdGg+PC9kZWZzPjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMCkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMzUgLTUxKSI+PHBhdGggZD0iTTI2My41MDYgNTFDMjY0LjcxNyA1MSAyNjUuODEzIDUxLjQ4MzcgMjY2LjYwNiA1Mi4yNjU4TDI2Ny4wNTIgNTIuNzk4NyAyNjcuNTM5IDUzLjYyODMgMjkwLjE4NSA5Mi4xODMxIDI5MC41NDUgOTIuNzk1IDI5MC42NTYgOTIuOTk2QzI5MC44NzcgOTMuNTEzIDI5MSA5NC4wODE1IDI5MSA5NC42NzgyIDI5MSA5Ny4wNjUxIDI4OS4wMzggOTkgMjg2LjYxNyA5OUwyNDAuMzgzIDk5QzIzNy45NjMgOTkgMjM2IDk3LjA2NTEgMjM2IDk0LjY3ODIgMjM2IDk0LjM3OTkgMjM2LjAzMSA5NC4wODg2IDIzNi4wODkgOTMuODA3MkwyMzYuMzM4IDkzLjAxNjIgMjM2Ljg1OCA5Mi4xMzE0IDI1OS40NzMgNTMuNjI5NCAyNTkuOTYxIDUyLjc5ODUgMjYwLjQwNyA1Mi4yNjU4QzI2MS4yIDUxLjQ4MzcgMjYyLjI5NiA1MSAyNjMuNTA2IDUxWk0yNjMuNTg2IDY2LjAxODNDMjYwLjczNyA2Ni4wMTgzIDI1OS4zMTMgNjcuMTI0NSAyNTkuMzEzIDY5LjMzNyAyNTkuMzEzIDY5LjYxMDIgMjU5LjMzMiA2OS44NjA4IDI1OS4zNzEgNzAuMDg4N0wyNjEuNzk1IDg0LjAxNjEgMjY1LjM4IDg0LjAxNjEgMjY3LjgyMSA2OS43NDc1QzI2Ny44NiA2OS43MzA5IDI2Ny44NzkgNjkuNTg3NyAyNjcuODc5IDY5LjMxNzkgMjY3Ljg3OSA2Ny4xMTgyIDI2Ni40NDggNjYuMDE4MyAyNjMuNTg2IDY2LjAxODNaTTI2My41NzYgODYuMDU0N0MyNjEuMDQ5IDg2LjA1NDcgMjU5Ljc4NiA4Ny4zMDA1IDI1OS43ODYgODkuNzkyMSAyNTkuNzg2IDkyLjI4MzcgMjYxLjA0OSA5My41Mjk1IDI2My41NzYgOTMuNTI5NSAyNjYuMTE2IDkzLjUyOTUgMjY3LjM4NyA5Mi4yODM3IDI2Ny4zODcgODkuNzkyMSAyNjcuMzg3IDg3LjMwMDUgMjY2LjExNiA4Ni4wNTQ3IDI2My41NzYgODYuMDU0N1oiIGZpbGw9IiNGRkU1MDAiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvZz48L3N2Zz4=) no-repeat 1rem/1.8rem, #b32121;
        padding: 1rem 1rem 1rem 3.7rem;
        color: white;
    }

    .blazor-error-boundary::after {
        content: "An error has occurred."
    }

    @media (max-width: 767.98px) {
        .main .top-row:not(.auth) {
            display: none;
        }

        .main .top-row.auth {
            justify-content: space-between;
        }

        .main .top-row a,
        .main .top-row .btn-link {
            margin-left: 0;
        }
    }

    @media (min-width: 768px) {
        #app {
            flex-direction: column;
            flex-grow: 1;
            overflow: hidden;
            height: 100%;
        }

        .top-row {
            position: relative;
            flex-direction: row;
            flex-shrink: 0;
            top: 0;
        }
    }
    /* Styling of standard progressbar */
    progress {
        border-radius: 7px;
        width: 100%;
        height: 6px;
        transition: width 350ms linear, opacity 500ms;
        opacity: 0;
        z-index: 10000;
        position: relative;
        top: 0;
        left: 0;
        display: block;
    }
    /* Apply in-progress class to show progress bar */
    progress.in-progress {
        opacity: 1;
    }
    /* Apply fade-progress class to start fading the progressbar out */
    progress.fade-progress {
        opacity: 0.7;
    }
    /* Apply no-progress class to completely hide the progressbar */
    progress.no-progress {
        opacity: 0;
    }
    /* background color of progressbar */
    progress::-webkit-progress-bar {
        background-color: var(--light-contrast);
        border-radius: 7px;
    }
    /* color of progress value indicator */
    progress::-webkit-progress-value {
        background-color: $primary;
        border-bottom-right-radius: 2px;
        border-top-right-radius: 2px;
    }

    progress::-moz-progress-bar {
        /* style rules */
    }

    .k-window {
        border: none;

        .k-window-titlebar {
            background-color: $dialog-header;
            color: $selected-text;
        }
    }

    .text-danger {
        color: $error;
    }
    // Telerik only apply border radius on some elements, while other elements like buttons and textbox'es are fixed on 0.25rem,
    // Here we override it and make sure all elements use our border-radius variable
    .k-rounded-md {
        border-radius: $border-radius;
    }
    //Generic class to create a grid for cards
    .data-card-grid {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
    /* Icon in a textbox (Like search or phone) */
    .textbox-icon-container {
        position: relative;
        /* these rules position the icon. Update with your own selector if you add HTML elements for click events */
        .k-icon {
            position: absolute;
            z-index: 1;
            height: 100%;
            left: 0.5em;
        }
        /* no floating label */
        input.k-textbox,
        /* for the floating label */
        .k-textbox input,
        .k-state-empty .k-label {
            padding-left: 2em;
        }
        /* this rule also positions the icon for the floating label scenario, tweak it with your own selectors if you use other HTML */
        &.with-label .k-icon {
            top: 0.5em;
        }
    }

    .link {
        &:hover {
            text-decoration: underline;
        }
    }

    .k-grid-filter {
        &.k-state-active {
            color: $secondary;
        }
    }

    .k-drawer-content {
        overflow: hidden !important;
    }

    .k-tooltip.k-popup .label {
        font-weight: bold;
    }

    .k-button-flat-base .k-button-icon:not(.k-i-close) {
        color: $primary;
    }
    //Fix drawer icons not aliged after update to bootstrap-kendo: v5.6.0
    nav.k-drawer-items ul li.k-drawer-item span.k-item-text {
        overflow: initial;
    }
}
